// @import url("antd/dist/antd.variable.min.css");
@tailwind base;
@layer base {
  img {
    @apply inline-block;
  }
}
@tailwind components;
@tailwind utilities;
@import "./variables.scss";
@import "./input.scss";
@import "./btn.scss";
@import "./text.scss";

@font-face {
    font-family: "Poppin-light";
    src: url('../fonts/Poppins-Light.ttf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
  font-family: "Poppin-medium";
  src: url('../fonts/Poppins-Medium.ttf');
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: "Poppin";
  src: url('../fonts/Poppins-Regular.ttf');
  font-weight: normal;
  font-size: 14px;
  font-style: normal;
}

.font--poppins{
  font-family: $fontPoppin;
}

.font--poppins-medium{
  font-family: $fontPoppinMedium;
}

.bg--gradient{
  background: linear-gradient(132.64deg, rgb(153 184 215 / 10%) 3.85%, rgba(255, 243, 252, 0.1215686275) 100.01%);
  overflow: hidden;
  &::before{
    content: url("../media/illustrations/bg-gradient-top.svg");
    left: 0rem;
    top: 0rem;
    position: fixed;
    z-index: 0;
  }
  &::after{
    content: url("../media/illustrations/bg-gradient-bottom.svg");
    right: 0rem;
    bottom: 0rem;
    position: fixed;
    z-index: 0;
  }
}

// .bg--gradient > div{
//   &::before{
//     content: url("../media/illustrations/bg-gradient-top.svg");
//     // left: 20rem;
//     // top: -14rem;
//     position: fixed;
//     // height: 31rem;
//     // width: 48rem;
//     // background-color: $success;
//     // background: linear-gradient(210deg, #73C09E 43.82%, #d1f0f7 82.09%);
//     // border-radius: 100%;
//     // opacity: .1;
//     z-index: 0;
//     // @apply blur-3xl
//   }
//   &::after{
//     content: url("../media/illustrations/bg-gradient-bottom.svg");
//     // right: -20rem;
//     bottom: 0rem;
//     position: fixed;
//     // height: 40rem;
//     // width: 60rem;
//     // background-color: $warning;
//     // border-radius: 100%;
//     // opacity: .1;
//     z-index: 0;
//     // @apply blur-3xl
//   }
// }

h1, h2, h3, h4, h5, h6 {
  font-family: $fontPoppinMedium;
  font-weight: 600;
  letter-spacing: -0.24px;
  line-height: 32px;
  line-height: normal;
}

div,p,a,span {
  font-family: $fontPoppin;
}

a:hover{
  color: $primary;
  text-decoration: underline;
}

h1{
  font-size: $mainHeading;
}

h2{
  font-size: $secondaryTitle;
}

h3{
  font-size: $tertiaryTitle;
}

h4{
  font-size: $fourthTitle;
}

h5{
  font-size: $fifthTitle;
}

h6{
  font-size: $sixthTitle;
}

.bg--primary {
  background-color: $primaryDark;
}
.bg--primary-light {
  background-color: $primary;
}
.bg--secondary {
  background-color: $secondary;
}
.bg--success {
  background-color: $success;
}
.bg--danger {
  background-color: $dangerDark;
}
.bg--danger-light {
  background-color: $dangerLight;
}
.bg--warning {
  background-color: $yellow;
}

.border--box{
  border: .09rem solid $greyLight;
}

.border--yellow{
  border: .09rem solid $yellow;
}

div.ant-progress-bg{
  height: 12px
}

div.ant-progress .ant-progress-success-bg, div.ant-progress .ant-progress-bg{
  background-color: $secondary
}

div.ant-steps{
  @apply font--poppins;
}

// Finished item color
div.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: $successDark;
  border-color: $successDark;
}

// Finished bar color
div.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: $successDark;
}

// Hover item
div.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon{
  border-color: transparent;
}

// Hover icon
div.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: $default;
}

// Hover text
div.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-title, :where(.css-dev-only-do-not-override-zcfrx9).ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle, :where(.css-dev-only-do-not-override-zcfrx9).ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: $default;
}

div.ant-steps.ant-steps-vertical >.ant-steps-item .ant-steps-item-description{
  color: $danger !important;
  font-size: 12px;
}

div[class^="ant-slider"], div[class*=" ant-slider"]{
  z-index: auto;
}

// finished icon color
div.ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon {
  color: white;
}

// Pending item color
div.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: $primary;
  border-color: $primary;
}

div.ant-steps .ant-steps-item-title{
  font-size: 13px;
}

div.ant-alert-error {
  background-color: $dangerExtraLight;
  border: 1px solid $dangerLight;
  .ant-alert-icon {
    color: $dangerDark;
  }
}

.ant-result-error {
  .ant-result-icon>.anticon {
    color: $dangerDark;
  }
}

div.ant-alert-success {
  background-color: $successExtraLight;
  border: 1px solid $successLight;
  .ant-alert-icon {
    color: $successDark;
  }
}

.ant-result-success {
  .ant-result-icon>.anticon {
    color: $successDark;
  }
}

.box-feedBack {
  .ant-checkbox-wrapper{
    .ant-checkbox{
      top: -.6rem
    }
  }
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner{
  border-color: $primary;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $secondary;
  border-color: $secondary;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: $secondary;
  border-color: transparent;
}

div.ant-tree .ant-tree-node-content-wrapper, .ant-tree .ant-tree-checkbox+span{
  padding: 0 8px;
}

div.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected, div.ant-tree .ant-tree-checkbox+span.ant-tree-node-selected {
  background-color: #dee2e6;
}

div.ant-tree.steps .ant-tree-node-content-wrapper.ant-tree-node-selected, div.ant-tree.steps .ant-tree-checkbox+span.ant-tree-node-selected {
  color: #fff;
  background-color: transparent;
  font-weight: bold;
}

div.ant-tree.steps.steps-doctor .ant-tree-node-content-wrapper.ant-tree-node-selected, div.ant-tree.steps .ant-tree-checkbox+span.ant-tree-node-selected {
  color: black;
  // background-color: transparent;
  font-weight: bold;
}

div.ant-tree.steps-doc .ant-tree-node-content-wrapper.ant-tree-node-selected, div.ant-tree.steps-doc .ant-tree-checkbox+span.ant-tree-node-selected {
  color: $textDefault;
  background-color: transparent;
  font-weight: bold;
}

div.ant-table-wrapper table{
  font-size: 13px;
}

span.ant-badge.ant-badge-status .ant-badge-status-text {
  font-size: inherit;
}

.ant-card.patient-details{
  .ant-card-body{
    padding: 0;
    .ant-tabs-content-holder{
      background: transparent;
    }
    .ant-tabs-nav{
      @apply px-8 bg-white
    }
  }
}

div.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: $secondary;
}

div.ant-tabs .ant-tabs-ink-bar{
  background: $secondary;
}

.public-DraftEditor-content{
  min-height: 250px;
}

div.ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected >.ant-table-cell{
  background: initial;
}

div.ant-carousel .slick-dots li button {
  background-color: $greyDark;
}

div.ant-carousel .slick-dots li.slick-active button {
  background-color: $greyDark;
}

/* Change la couleur des dots carousel */
.carousel-todo .slick-dots li button {
  margin-top: 10px;
  background-color: white !important; /* Couleur des dots non actifs */
  opacity: 0.5; /* Transparence des dots non actifs */
}

.carousel-todo .slick-dots li.slick-active button {
  margin-top: 10px;
  background-color: white !important; /* Couleur des dots actifs */
  opacity: 1; /* Dots actifs pleinement visibles */
}

li.ant-list-item.small-gap{
  padding: 10px;
  padding-inline: 0px;
}

@media (min-width: 768px) {
  li.ant-list-item.small-gap{
    padding: 5px;
    padding-inline: 0px;
  }
}

.admin-carousel {
  .slick-dots.slick-dots-bottom{
    display: flex;
    justify-content: start;
    align-items: start;
  }
}
